import { Module } from "vuex";

import { Page, ConsultationFormValues } from "@/types";
import { api } from "@/util/axios";
import Vue from "vue";

type clientConsultationState = {
  consultationFormValuesPage: Page<ConsultationFormValues>;
};

const clientConsultation: Module<clientConsultationState, unknown> = {
  namespaced: true,
  state: () => ({
    consultationFormValuesPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    consultationFormValuesPage: (state) => state.consultationFormValuesPage,
    getConsultationFormValues: (state) => (formId: string) =>
      state.consultationFormValuesPage.docs.find(
        (form) => form._id === formId || form.consultationForm._id === formId
      ),
  },
  mutations: {
    ADD_CLIENT_CONSULTATIONFORM: (state, _form) => {
      let idx = -1;
      state.consultationFormValuesPage.docs.map((r, i) => {
        if (r._id === _form._id) idx = i;
      });
      if (idx === -1) state.consultationFormValuesPage.docs.push(_form);
      else Vue.set(state.consultationFormValuesPage.docs, idx, _form);
    },
    SET_CLIENT_CONSULTATIONFORM_PAGE: (state, list) => {
      state.consultationFormValuesPage = list;
    },
  },
  actions: {
    fetchClientConsultationList(context, params = "") {
      api
        .get(`/v1/consultation-form-values${params}`)
        .then((response) => {
          context.commit(
            "SET_CLIENT_CONSULTATIONFORM_PAGE",
            response.data.consultationFormPage
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async fetchConsultationFormValues(context, params = "") {
      return api
        .get(`/v1/consultation-form-values${params}`)
        .then((response) => {
          context.commit(
            "ADD_CLIENT_CONSULTATIONFORM",
            response.data.consultationFormValues
          );
          return response.data.consultationFormValues;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createConsultationFormValues(context, payload) {
      return await api
        .post(`v1/consultation-form-values`, payload)
        .then((response) => {
          context.commit(
            "ADD_CLIENT_CONSULTATIONFORM",
            response.data.consultationFormValues
          );
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Client Consultation Form Submitted",
            },
            { root: true }
          );
          return response.data.consultationFormValues;
        })
        .catch((error) => {
          console.log(error);
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async updateConsultationFormValues(context, payload) {
      return await api
        .put(`v1/consultation-form-values`, payload)
        .then((res) => {
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Client Consultation Form updated",
            },
            { root: true }
          );
          return res.data.consultationFormValues;
        })
        .catch((error) => {
          console.log(error);
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default clientConsultation;
